
.card-img-top {
    padding: 1rem;
    object-fit: contain;
    height: 60%;
}
.vl {
    border-left: 1px solid grey;
    height: 100px;
  }
   